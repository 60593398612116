import React from 'react';

function LayerSelect(props) {

	return (
		<div className="h-8 border-t-2 border-gray-600 py-2">
			<select id="impactType" name="impactType" className="float-right form-select form-select-xs text-sm relative z-50 w-56 mb-4 p-0 text-gray-900" aria-label="impact type filter selector" value={props.layerFilter} onChange={(e) => props.setLayerFilter(e.target.value)}>
				{props.layers.map((layer) => {
					return <option value={layer} key={layer} style={{color: props.colourScheme[layer]}} className="w-64">{layer}</option>
				})}
			</select>
		</div>
	)
}

export default LayerSelect;
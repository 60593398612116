import React, {useEffect, useState} from 'react';
import BarChart from './Chart';


function DataSidebar(props) {

	const [chartData, setChartData] = useState({});

	const [chartTitle, setChartTitle] = useState('Lives Touched by Impact Metric');

	const [chartBlurb, setChartBlurb] = useState(true);

	const fetchCaseStudyImpactMetrics = async() => {
		const res = await fetch(projects_metrics_url);
		const data = await res.json();
		setChartData({
			labels: Object.keys(data).map(key => key == 'All' ? null : key),
			datasets: [
				{
					label: 'Case Studies',
					data: Object.keys(data).map(key => key == 'All' ? null : data[key]),
		            backgroundColor: [
		              "#6ab54c",
		              "#9d5ccf",
		              "#c6a840",
		              "#636ac5",
		              "#d35d2c",
		              "#5e99d3",
		              "#d35259",
		              "#4bb091",
		              "#c24ead",
		              "#708139",
		              "#da4984",
		              "#b9784a",
		              "#cb8bca"
		            ]
				}
			]
		});
		setChartTitle('Case Studies by Primary Impact Type');
		setChartBlurb(false);
	}

	const fetchLivesTouchedMetrics = async() => {
		const res = await fetch(lives_touched_url);
		const data = await res.json();
		setChartData({
			labels: Object.keys(data).map(key => key),
			datasets: [
				{
					label: 'People',
					data: Object.keys(data).map(key => data[key]),
		            backgroundColor: [
		              "#c6a840",
		              "#6ab54c",
		              "#d35d2c",
		              "#4bb091",
		              "#c24ead"
		            ]
				}
			]		
		});
		setChartTitle('People by Impact Metric');
		setChartBlurb(true);
	}

	useEffect(() => {
		fetchLivesTouchedMetrics();
	}, []);

	

	return (
		<div>
			<p className="text-sm mb-4 border-t-2 border-b-2 border-gray-600 py-4">The map on the right shows the places where UCL research has had impact across the world. Each point on the map represents a REF2021 impact case study. Click on a point to find out more about the research and where it happened.</p>

			<BarChart chartData={chartData} chartTitle={chartTitle} chartBlurb={chartBlurb} />
			<div className="grid overflow-hidden grid-cols-2 grid-rows-1 gap-2 mt-2 justify-items-center border-t-2 border-gray-600 pt-4">
				<div className="text-xs">
					<button href="#" className="btn-indigo" onClick={(e) => {
						e.preventDefault();
						fetchLivesTouchedMetrics();
					}}>People</button>
				</div>
				<div className="text-xs content-center">
					<button href="#" className="btn-indigo" onClick={(e) => {
						e.preventDefault();
						fetchCaseStudyImpactMetrics();
					}}>Case Studies</button>
				</div>
			</div>
		</div>
	)
}

export default DataSidebar;
import React from 'react';

function DetailPopup(props) {
	
	let button = null;

	if (typeof(props.caseStudyId) != 'undefined') {
		button = <button 
			type="button" 
			className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mt-4"
			onClick={props.onClickShowCaseStudy}
			data-casestudy={props.caseStudyId}
			data-fontcolour={props.fontColour}
			>
			Read Case Study
		</button>
	}
	


	return (<div>
				<p className="text-xs" style={{color: props.fontColour }}>{props.name}<br />
				</p>
				<div className="flex justify-start">
					{button}
				</div>
			</div>)
}

export default DetailPopup;
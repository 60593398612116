import React from 'react';
import { Bar } from "react-chartjs-2";

function BarChart(props) {

	if (!props.chartData.labels) {
		return (
			<p>Loading...</p>
		)
	}



	return (
		<div>
		<div className="h-96 w-full">
	      <Bar
	        data={props.chartData}
	        options={{
			indexAxis: 'y',
			tooltips: {
				mode: 'nearest'
			},
			scales: {
				x: {
					grid: {
						display: false
					},
					title: {
						display: false
					}
				},
				y: {
					grid: {
						display: false
					},
					title: {
						display: false
					}
				}
			},
			plugins: {
				legend: {
					display: false,
				},
				title: {
					display: true,
					text: props.chartTitle,
					position: 'top'
				}
			},
			animations: true,
	        }}
	        height={350}
	      />
	    </div>
	    {props.chartBlurb ? <div><p className="text-sm mt-10 border-t-2 border-gray-600 py-4">The chart above shows a running total of the number of people whose lives have been affected by UCL's research across different domains.</p><p className="text-sm">These figures are incomplete, but our analysis so far suggests that the numbers for each are at least those shown.</p></div> : <div><p className="text-sm mt-10 border-t-2 border-gray-600 pt-4">The chart above shows the distribution of UCL's Impact Case Studies across different domains of impact.</p></div>}
	    </div>
	)
}

export default BarChart;
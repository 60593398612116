import React from 'react';

function DataSwitcher(props) {

	return (
		<div className="text-sm absolute bottom-4 w-11/12">
			
			<h3 className="font-serif text-xl font-bold tracking-widest text-gray-800 mb-2 border-b border-gray-700">Demographics</h3>
			{
				props.demographics.map((demog) =>
					(
						<div key={demog.name}>
							<input type="radio" id={demog.name} name="demographics" className="form-radio" value={demog.name} defaultChecked={demog.selected} 
								onChange={(e) => {
										const options = [...props.demographics]
										options.forEach(opt => {
											if (opt.name == e.target.value) {
												opt.selected = e.target.checked;
											} else {
												opt.selected = false;
											}
										});
										props.setDemographics(options);
									}
								}
							/>
							<label htmlFor={demog.name} className="px-2">{demog.label}</label>
						</div>
					)
				)
			}
			<h3 className="font-serif text-xl font-bold tracking-widest text-gray-800 mb-2 border-b border-gray-700 pt-4">Activities</h3>
			{
				props.organisations.map((org) =>
					(
						<div key={org.name}>
							<input type="checkbox" id={org.name} name={org.name} defaultChecked={org.selected}
								onChange={(e) => {
									const options = [...props.organisations]
									options.forEach(opt => {
										if (opt.name == e.target.name) {
											opt.selected = e.target.checked;
										}
									});
									props.setOrganisations(options);
									}
								}
							></input>
							<label htmlFor={org.id} className="px-2">{org.label}</label>
						</div>
					)
				)
			}
			{
				props.activities.map((option) =>
					(
						<div key={option.name}>
							<h2 className="font-sans font-semibold uppercase tracking-widest text-gray-700 pt-4 mb-2">{option.label}</h2>
						
							<select name={option.name} id={option.name + '-select'} className="form-select m-0 p-0 w-full rounded-sm text-sm" defaultValue='all'
								onChange={(e)=> {
									const name = e.target.name;
									const options = [...props.activities];
									const value = e.target.value;
									options.forEach((opt) => {
										if (opt.name == name) {
											opt.choices.forEach((choice) => {
												if (choice.name == value) {
													choice.selected = true;
												} else {
													choice.selected = false;
												}
											});
										}
									});
									props.onActivityChange(options);
								}}
							>
								{
									option.choices.map((choice) =>
										(
											<option key={choice.name} value={choice.name}>{choice.label}</option>
										)
									)
								}
							</select>
						</div>
					)
				)
			}
		</div>
	)
}

export default DataSwitcher;
import React, { useRef, useEffect, useState } from 'react';
import '/src/styles.css';
import Map from './components/BCMap';
import DataSwitcher from './components/DataSwitcher';
import CaseStudyOverlay from './components/CaseStudyOverlay';
import PageOverlay from './components/PageOverlay';
import DataSidebar from './components/DataSidebar';
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {

  // Map / map filter state

  const [map, setMap] = useState(null);
  const [isMapFiltered, setIsMapFiltered] = useState(false);

  // CaseStudyOverlay state

  const [overLayOpen, setOverLayOpen] = useState(false);
  const [overLayContent, setOverLayContent] = useState({title: 'none', 'body': 'none'});
  const [fontColour, setFontColour] = useState('#222222');

  const [pageOpen, setPageOpen] = useState(false);
  const [pageContent, setPageContent] = useState({'title': 'none', 'body': 'none'});



  const onClickShowCaseStudy = e => {
  	const casestudy = e.target.dataset.casestudy;
    const fontColour = e.target.dataset.fontcolour;
  	fetch('/api/1.0/casestudies/detail/' + casestudy)
  		.then(response => response.json())
  		.then(data => {
  			setOverLayContent(data.properties);
        setFontColour(fontColour);
  			setOverLayOpen(true);
        setPageOpen(false);
  		});
  }

  const onClickShowPage = e => {
    const id = e.target.dataset.id;
    fetch('/api/1.0/page/' + id)
      .then(response => response.json())
      .then(data => {
        setPageContent(data);
        setPageOpen(true);
        setOverLayOpen(false);
      });
  }


  return (
    <div>
      <div className="hidden md:flex md:w-96 md:flex-col md:fixed md:inset-y-0">
      	<div className="flex flex-col flex-grow pt-5 bg-gray-900 overflow-y-auto shadow z-50">
	        <div className="flex items-center flex-shrink-0 px-4">
	          <h1 className="text-xl leading-6 font-sans font-bold tracking-widest text-gray-200">UCL 2021 REF Impact Map | Prototype</h1>
	        </div>
	        <div className="mt-5 flex-1 flex flex-col px-4 font-sans relative text-gray-200">
		        <DataSidebar />
	        </div>
        </div>
      </div>
      <div className="md:pl-96 flex flex-col flex-1 h-screen">
          <div className="h-16 bg-gray-900 flex justify-end items-center space-x-8 pr-8 text-gray-200">
            <div>
              <a href="#" onClick={onClickShowPage} data-id="1">About</a>
            </div>
          </div>
	        <div className="h-full">
            <Map map={map} setMap={setMap} onClickShowCaseStudy={onClickShowCaseStudy} isMapFiltered={isMapFiltered} setIsMapFiltered={setIsMapFiltered} />
	        </div>
	        <CaseStudyOverlay open={overLayOpen} setOpen={setOverLayOpen} content={overLayContent} fontColour={fontColour}/>
          <PageOverlay open={pageOpen} setPageOpen={setPageOpen} content={pageContent} />
      </div>
      
    </div>
  );
}

export default App;
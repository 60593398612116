import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import graph from '../data/graph.json';
import ReactDOM from 'react-dom';
import DetailPopup from './DetailPopup';
import LayerSelect from './LayerSelect';
import Legend from './Legend';
import {point, featureCollection} from '@turf/helpers';
import nearestPoint from '@turf/nearest-point';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyb25vY2FydG8iLCJhIjoiY2trbnRnajFvM2xocTJvcGF5czBsdGQ4byJ9.4AKwwY23PdK6ZD5lJUhaYQ';

function Map(props) {

	const mapContainerRef = useRef(null);

	const onClickShowCaseStudy = props.onClickShowCaseStudy;

	const [layerData, setLayerData] = useState({});

	const [layerFilter, setLayerFilter] = useState('All');

	const [geogFilter, setGeogFilter] = useState('Place');

	// Feels a bit hacky, this, but I need to be able to access the most recent layer filter state from inside map.on('click') to preserve the filters on the map after clicking on a case study

	//const layerFilterStateRef = React.useRef(layerFilter);
	//const geogFilterStateRef = React.useRef(geogFilter);

	const layerFilterStateRef = React.useRef('All');
	const geogFilterStateRef = React.useRef('Place');

	const getGeogFilter = () => {
		if (geogFilterStateRef.current == 'Place') {
			return ['all', ['==', ['get', 'resolution'], 0]];
		}
		if (geogFilterStateRef.current == 'Country') {
			return ['all', ['==', ['get', 'resolution'], 1]];
		}
		if (geogFilterStateRef.current == 'Continent') {
			return ['all', ['==', ['get', 'resolution'], 2]];
		}
	}

	const setLayerFilterState = (data) => {
		layerFilterStateRef.current = data;
		setLayerFilter(data);
	}

	const setGeogFilterState = (data) => {
		geogFilterStateRef.current = data;
		setGeogFilter(data);
	}

	const clickPopupRef = useRef(new mapboxgl.Popup({
    	closeOnClick: true
	}));


	let map = props.map;
	let [isMapFiltered, setIsMapFiltered] = useState(false);

	const colourScheme = {
		'Impact on creativity, culture and society': '#6ab54c',
		'Impact on practitioners & professional services': '#9d5ccf',
		'Impact on production': '#c6a840',
		'COVID-19': '#636ac5',
		'Impact on Education': '#d35d2c',
		'Impact on the environment': '#5e99d3',
		'Impact on understanding, learning & participation': '#d35259',
		'Impacts on health, wellbeing & animal welfare': '#4bb091',
		'Impact on social welfare': '#c24ead',
		'Impact on commerce & economy': '#708139',
		'Impact on public policy, law & services': '#da4984',
		'New space mission': '#b9784a',
		'SDG': '#cb8bca',
		'All': '#a65370',
		'Commercial': '#c6a840',
		'Cultural': '#6ab54c',
		'Education': '#d35d2c',
		'Health': '#4bb091',
		'Societal': '#c24ead'
	}


	useEffect(() => {
		mapboxgl.accessToken = 'pk.eyJ1IjoiZGR1bmMyMyIsImEiOiJxQVhaaVhjIn0.T7vhn1bLeeQoHCsWZ_mp2g';
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: 'mapbox://styles/ddunc23/ckohaiphc141b18qvpl0rh062',
			center: [-2.547859, 54.003644],
			zoom: 5,
			attributionControl: false
		});

		map.addControl(new mapboxgl.NavigationControl(), 'top-left');

		map.on('load', () => {
		
			map.addSource('ref', { 
				type: 'geojson', 
				data: points_url
			});
			
			map.addSource('countries', {
				type: 'geojson',
				data: countries_url
			});

			map.addSource('country-boundaries', {
				type: 'vector',
				url: 'mapbox://mapbox.country-boundaries-v1'
			});

			map.addSource('mapbox-streets', {
				type: 'vector',
				url: 'mapbox://mapbox.mapbox-streets-v8'
			});

			map.addSource('continents', {
				type: 'vector',
				url: 'mapbox://chronocarto.8849b537'
			});

			map.addLayer({
				id: 'water_outlines',
				'type': 'line',
				'source': 'continents',
				'source-layer': 'continents-4nrkfx',
				layout: {
					'visibility': 'none'
				},
				paint: {
					'line-color': '#917edd',
					'line-width': 1,
					'line-opacity': 0.8
				},
			});

			map.addLayer({
				id: 'country_fill',
				type: 'fill',
				source: 'countries',
				layout: {
					'visibility': 'none'
				},
				paint: {
				}
			});

			map.addLayer({
				id: 'country_outlines',
				type: 'line',
				source: 'country-boundaries',
				'source-layer': 'country_boundaries',
				layout: {
					'visibility': 'none'
				},
				paint: {
					'line-color': '#917edd',
					'line-width': 0.5,
					'line-opacity': 0.5
				},
			});


			map.addLayer({
				id: 'refdata',
				type: 'circle',
				source: 'ref',
				filter: ['==', ['get', 'resolution'], 0],
				layout: {
					
				},
				paint: {
					'circle-color': 
						[
							'case', 
							['==', ['get', 'impact_type'], 'Impact on creativity, culture and society'], 
							colourScheme['Impact on creativity, culture and society'],
							['==', ['get', 'impact_type'], 'Impact on practitioners & professional services'], 
							colourScheme['Impact on practitioners & professional services'], 
							['==', ['get', 'impact_type'], 'Impact on production'], 
							colourScheme['Impact on production'], 
							['==', ['get', 'impact_type'], 'COVID-19'], 
							colourScheme['COVID-19'],
							['==', ['get', 'impact_type'], 'Impact on Education'], 
							colourScheme['Impact on Education'],
							['==', ['get', 'impact_type'], 'Impact on the environment'], 
							colourScheme['Impact on the environment'], 
							['==', ['get', 'impact_type'], 'Impact on understanding, learning & participation'], 
							colourScheme['Impact on understanding, learning & participation'], 
							['==', ['get', 'impact_type'], 'Impacts on health, wellbeing & animal welfare'], 
							colourScheme['Impacts on health, wellbeing & animal welfare'], 
							['==', ['get', 'impact_type'], 'Impact on social welfare'], 
							colourScheme['Impact on social welfare'],
							['==', ['get', 'impact_type'], 'Impact on commerce & economy'], 
							colourScheme['Impact on commerce & economy'],
							['==', ['get', 'impact_type'], 'Impact on public policy, law & services'], 
							colourScheme['Impact on public policy, law & services'], 
							['==', ['get', 'impact_type'], 'New space mission'], 
							colourScheme['New space mission'],
							['==', ['get', 'impact_type'], 'SDG'], 
							colourScheme['SDG'], 
							'#073642'
						],
					'circle-radius': [
						"interpolate", ["linear"], ["zoom"],
							2,
							['match', ['get', 'resolution'], 
								0,1,
								1,3,
								2,5,
								1
							],
							15,
							['match', ['get', 'resolution'], 
								0,10,
								1,20,
								2,30,
								10
							]
						],
					'circle-blur': [
						'case',
						['==', ['get', 'resolution'], 0],
						0,
						['==', ['get', 'resolution'], 1],
						0.7,
						['==', ['get', 'resolution'], 2],
						1,
						0
					]
				}
			});

			// Add attribution

			class LogoControl {
				onAdd(map) {
					this._map = map;
					this._container = document.createElement('div');
					this._container.className = 'mapboxgl-ctrl';
					this._container.innerHTML = '<span class="attributionText"><a href="https://www.ucl.ac.uk/bartlett/casa/"><img class="w-10" src="/static/img/CASA_logo.png" %}" alt="CASA logo" /></a> <a href="https://www.ucl.ac.uk/bartlett/casa/" class="text-gray-200">Powered by CASA</a></span>';
					return this._container;
				}

				onRemove() {
					this._container.parentNode.removeChild(this._container);
					this._map = undefined;
				}
			}

			const logoControl = new LogoControl();

			map.addControl(logoControl, 'bottom-right');

			// Hover interactions

			const hoverPopup = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false
			});

			map.on('mouseenter', 'refdata', function(e) {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = 'pointer';

				const coordinates = e.lngLat;

				const fontColour = colourScheme[e.features[0].properties.impact_type];
				
				let description = '';

				if (e.features[0].properties.title	 == '') {
					description = '<span style="color: '+ fontColour +'">' + e.features[0].properties.file_name + '</span>';
				} else {
					description = '<span style="color: '+ fontColour +'">' + e.features[0].properties.title.split(':')[0] + '</span>';
				}

				 
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}
				 
				// Populate the popup and set its coordinates
				// based on the feature found.
				hoverPopup.setLngLat(coordinates).setHTML(description).addTo(map);
				});
				 
				map.on('mouseleave', 'refdata', function () {
					map.getCanvas().style.cursor = '';
					hoverPopup.remove();
			});

			// Click interactions

			const clickPopup = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				maxWidth: '300px'
			});
			
			map.on('click', function(e) {
				
				const features = map.queryRenderedFeatures(e.point);
				const refFeature = features.filter((el) => el.layer.source == 'ref').pop();

				if (refFeature) {

					hoverPopup.remove();

					const referencePoint = point([e.lngLat.lng, e.lngLat.lat]);
					let points = [];
					for (let i=0; i<refFeature.geometry.coordinates.length; i++) {
						if (refFeature.geometry.type == 'MultiPoint') {
							points.push(point(refFeature.geometry.coordinates[i]));	
						} else if (refFeature.geometry.type == 'Point') {
							points.push(point(refFeature.geometry.coordinates));
						}
					}
					points = featureCollection(points);
					const coordinates = nearestPoint(referencePoint, points).geometry.coordinates;
					
					const caseStudyId = refFeature.properties.pk;
					const impactType = refFeature.properties.impact_type;
					const file_name = refFeature.properties.file_name;

					const props = refFeature.properties;

					// Highlight the countries associated with this case study
					let countries = props.geog_str.split(',');
					map.setFilter('country_fill', ['in', ['get', 'CNTRY_NAME'], ['literal', countries]]);
					map.setLayoutProperty('country_fill', 'visibility', 'visible');
					map.setPaintProperty('country_fill', 'fill-color', colourScheme[impactType]);
					map.setPaintProperty('country_fill', 'fill-opacity', 0.1);


					// Todo: Re-write this so it uses the pointlocation geometries as the multipoint geometries are inaccurate - you've added an API endpoint for this
					fetch('/api/1.0/casestudies/' + caseStudyId + '/')
					.then(response => response.json())
					.then(data => {
						const origin = coordinates;					
						const spatialGraph = {
							'type': 'FeatureCollection',
							'features': [
							]
						};

						for (let i=0; i<data.geometry.coordinates.length; i++) {
							const coords = data.geometry.coordinates[i];
							const lineString = {
								'type': 'Feature',
								'geometry': {
									'type': 'LineString',
									'properties': {},
									'coordinates': [origin, coords]
								}
							};
							spatialGraph.features.push(lineString);
						}

						map.addSource('LineString', {
							'type': 'geojson',
							'data': spatialGraph
						});
						
						map.addLayer({
							'id': 'LineString',
							'type': 'line',
							'source': 'LineString',
							'layout': {
							'line-join': 'round',
							'line-cap': 'round'
							},
							'paint': {
							'line-color': colourScheme[impactType],
							'line-width': 1
							}
						});

						let bounds = new mapboxgl.LngLatBounds();
						data.geometry.coordinates.forEach(coords => {
							try {
								bounds.extend(coords);
							} catch(err) {
								console.log(err);
							}
						});

						map.fitBounds(bounds, {
							padding: {top: 30, bottom: 30, left: 30, right: 200}
						});

						const countryJson = map.querySourceFeatures('countries', {
							sourceLayer: 'countries',
							filter: ['in', ['get', 'CNTRY_NAME'], ['literal', countries]]
						});
						
						countryJson.forEach(country => {
							country.geometry.coordinates.forEach(coords => {
								coords.forEach(latlng => {
									try {
										bounds.extend(latlng);	
									} catch(err) {
										console.log(err);
									}
									
								});
							});
						});


						map.fitBounds(bounds, {
							padding: {top: 30, bottom: 30, left: 30, right: 250}
						});

						fetch('/api/1.0/casestudies/detail/' + caseStudyId)
							.then(response => response.json())
							.then(data => {

								const fontColour = colourScheme[impactType];
								const caseStudyData = data.properties;
								let title = '';

								// If there isn't a short title, use the long one
								if (props.title == '') {
									title = props.file_name;
								} else {
									title = props.title;
								}

								const detailPopupHtmlNode = document.createElement('div');

								ReactDOM.render(<DetailPopup name={title} onClickShowCaseStudy={onClickShowCaseStudy} caseStudyId={caseStudyId} fontColour={fontColour} />, detailPopupHtmlNode);

								clickPopupRef.current.setLngLat(coordinates).setDOMContent(detailPopupHtmlNode).addTo(map);
							});
						});			

						// Set the filter to hide all the other case studies

						map.setFilter('refdata', ['==', ['get', 'pk'], caseStudyId]);
						setIsMapFiltered(true);

						// Set the layer style so the points are more visible

						map.setPaintProperty('refdata', 'circle-radius',  ["interpolate", ["linear"], ["zoom"], 2, 6, 10, 4, 20, 40]);

				} else {
					
					// Either remove all filters or set the map filter state to match the value in the dropdown

					const geogFilter = getGeogFilter();

					if (layerFilterStateRef.current == 'All') {
						map.setFilter('refdata', geogFilter);
					} else {
						map.setFilter('refdata', ['all', ['==', ['get', 'impact_type'], layerFilterStateRef.current], geogFilter]);	
					}
					
					// Hide country shapes
					map.setLayoutProperty('country_fill', 'visibility', 'none');

					map.setPaintProperty('refdata', 'circle-radius', [
						"interpolate", ["linear"], ["zoom"],
							2,
							['match', ['get', 'resolution'], 
								0,1,
								1,3,
								2,5,
								1
							],
							15,
							['match', ['get', 'resolution'], 
								0,10,
								1,20,
								2,30,
								10
							]
						]);
					if (map.getSource('LineString') != undefined) {
						map.removeLayer('LineString');
						map.removeSource('LineString');
					}
					clickPopup.remove();		
				}	
			});
			
			props.setMap(map);
		});
	
		return () => map.remove();
	}, []);

	useEffect(() => {
		const getLayerData = async() => {
			const res = await fetch(projects_metrics_url);
			const data = await res.json();
			setLayerData(data);
		}
		getLayerData();
	}, []);

	useEffect(() => {
		if (map && map.loaded()) {

			// Set refdata filter
			if (layerFilter == 'All') {
				const geogFilter = getGeogFilter();
				map.setFilter('refdata', geogFilter);
			} else {
				const geogFilter = getGeogFilter();
				map.setFilter('refdata', ['all', ['==', ['get', 'impact_type'], layerFilterStateRef.current], geogFilter]);
			}

			// toggle country/continent outline visibility
			if (geogFilterStateRef.current == 'Country') {
				map.setLayoutProperty('country_outlines', 'visibility', 'visible');
				map.setLayoutProperty('water_outlines', 'visibility', 'none');
			} else if (geogFilterStateRef.current == 'Place') {
				map.setLayoutProperty('country_outlines', 'visibility', 'none');
				map.setLayoutProperty('water_outlines', 'visibility', 'none');
			} else if (geogFilterStateRef.current == 'Continent') {
				map.setLayoutProperty('country_outlines', 'visibility', 'none'); 
				map.setLayoutProperty('water_outlines', 'visibility', 'visible');
			}
		}
	}, [layerFilter, geogFilter]);

	return (
		<div className="h-full">
	      <div ref={mapContainerRef} className='map-container h-full'>
	      	<div className="map-overlay overlay-topright">
				<Legend colourScheme={colourScheme} layers={Object.keys(layerData)} layerFilter={layerFilter} setLayerFilter={setLayerFilterState} setGeogFilter={setGeogFilterState}/>
			</div>
	      </div>
	    </div>
	)

}

export default Map;
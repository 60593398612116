/* This example requires Tailwind CSS v2.0+ */
import React, { useRef, useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function CaseStudyOverlay(props) {

  function createMarkup() {
    return {__html: props.content.summary}
  }

  /*
  let beneficiaries = null;

  if (props.content.beneficiaries) {
    beneficiaries = props.content.beneficiaries.map(bene => (
        (bene.name == 'Beneficiaries') ? null : <span>{bene.name}, </span>
      ));
  }
  */

  let topics = [];
  
  /*if (props.content.research_topics) {
    topics = props.content.research_topics.map(topic => (
        <span>{topic.name}, </span>
      ));
  }*/

  if (props.content.research_topics) {
    props.content.research_topics.forEach((topic, index) => {
      if (index == props.content.research_topics.length -1) {
        topics.push(<span key={index}>{topic.name} </span>)
      } else {
        topics.push(<span key={index}>{topic.name}, </span>)
      }
    });
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={props.setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col py-6 bg-gray-100 shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-xl leading-6 font-sans font-bold tracking-widest text-gray-100" style={{color: props.fontColour}}>{props.content.title}</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => props.setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    <div className="absolute inset-0 px-4 sm:px-6 text-gray-800" id="contentContainer">
                    {props.content.top_impact_type_parent ? <h4 className="font-bold tracking-widest mb-2" style={{color: props.fontColour}}>{props.content.top_impact_type_parent}</h4> : null }
                    <hr className="border-t-2 border-gray-400 py-2" />
                    {props.content.investigators ? <h4 className="font-bold tracking-widest mb-2" style={{color: props.fontColour}}>Investigators</h4> : null }
                    <p className="mb-8">{props.content.investigators ? props.content.investigators : null}</p>
                    {props.content.beneficiaries ? <h4 className="font-bold tracking-widest mb-2" style={{color: props.fontColour}}>Who has this made a difference to?</h4> : null}
                    <p className="mb-8">{props.content.beneficiaries}</p>
                    {topics ? <h4 className="font-bold tracking-widest mb-2" style={{color: props.fontColour}}>Research Areas</h4> : null}
                    <p className="mb-8">{topics}</p>
                    {props.content.summary ? <h4 className="font-bold tracking-widest mb-2" style={{color: props.fontColour}}>Impact Summary</h4> : null }
                    <div dangerouslySetInnerHTML={createMarkup()} className="mb-8"></div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

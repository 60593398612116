import React from 'react';
import LayerSelect from './LayerSelect';

function Legend(props) {
	
	const blocks = Object.keys(props.colourScheme).slice(0,11).map(key => {
		const style = {'color': props.colourScheme[key]};
		return (
			<div key={key}  className="mb-1">
				<input type="radio" style={style} value={key} id={key} name="category" onChange={(e) => props.setLayerFilter(e.target.value)}></input>
				<label htmlFor={key} style={style} className="ml-2">{key}</label>
			</div>
		)
	});
	
	blocks.unshift(
		<div key='all'  className="mb-1">
			<input type="radio" value='All' id='All' name="category" onChange={(e) => props.setLayerFilter(e.target.value)} defaultChecked></input>
			<label htmlFor='All' className="ml-2">All</label>
		</div>
	);
	

	blocks.push(
		<div key='resTitle' className="pt-2 border-t-2 border-gray-700">
			<h3 className="text-center text-xs font-sans font-bold mb-2">Spatial Resolution</h3>
		</div>
	);

	blocks.push(
		<div key="place" className="mb-1">
			<input type="radio" value="Place" id="Place" name="resolution" onChange={(e) => props.setGeogFilter(e.target.value)} defaultChecked></input>
			<label htmlFor="Place" className="ml-2">Place</label>
		</div>
	);

	blocks.push(
		<div key="country" className="mb-1">
			<input type="radio" value="Country" id="Country" name="resolution" onChange={(e) => props.setGeogFilter(e.target.value)}></input>
			<label htmlFor="Country" className="ml-2">Country</label>
		</div>
	);

	blocks.push(
		<div key="continent" className="mb-1">
			<input type="radio" value="Continent" id="Continent" name="resolution" onChange={(e) => props.setGeogFilter(e.target.value)}></input>
			<label htmlFor="Continent" className="ml-2">Continent</label>
		</div>
	);

	return (
		<div className="p-4 mb-4 w-64 text-yellow-50 bg-gray-900">
			<h3 className="text-center text-xs font-sans font-bold mb-2">Impact Types</h3>
			<div>
				{blocks}
			</div>
		</div>
	);
}

export default Legend;